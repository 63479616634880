import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Header from "../../components/header"
import Footer from "../../components/footer"
import "../../styles/style.css"
import Services from "../../components/services/servicios_seguridad"
import Location from "../../components/services/location_seguridad"

const SeguridadSevilla = () => {
  const data = useStaticQuery(graphql`
    query imagesSeguridadSevilla {
      image: allFile(
        filter: { relativeDirectory: { eq: "compania" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      },
      logos: allFile(
        filter: { relativeDirectory: { eq: "offering/logos" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `) 
  
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Seguridad informática en SEVILLA | Qualoom</title>
        <meta name="title" content="Seguridad informática en SEVILLA | Qualoom"/>
        <meta name="description" content="Profesionales especializados en Seguridad Informática en Sevilla. En Qualoom ofrecemos un Servicio de Asesoramiento Gratuito. Infórmate ¡YA!"/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/seguridad-informatica/sevilla/"/>
        <link rel="canonical" href="https://www.qualoom.es/seguridad-informatica/sevilla/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/seguridad-informatica/sevilla/"/>
        <meta property="og:title" content="Seguridad informática en SEVILLA | Qualoom"/>
        <meta property="og:description" content="Profesionales especializados en Seguridad Informática en Sevilla. En Qualoom ofrecemos un Servicio de Asesoramiento Gratuito. Infórmate ¡YA!"/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3qEkBiWp6XWYzJYweuZVSK/00681335572a0fe04ef29bc8dba9096b/tw_cards_servicios.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/seguridad-informatica/sevilla/"/>
        <meta property="twitter:title" content="Seguridad informática en SEVILLA | Qualoom"/>
        <meta property="twitter:description" content="Profesionales especializados en Seguridad Informática en Sevilla. En Qualoom ofrecemos un Servicio de Asesoramiento Gratuito. Infórmate ¡YA!"/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3qEkBiWp6XWYzJYweuZVSK/00681335572a0fe04ef29bc8dba9096b/tw_cards_servicios.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/seguridad-informatica/sevilla/#breadcrumb",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://www.qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Seguridad informática", "item": {"@id": "https://www.qualoom.es/seguridad-informatica/", "name": "Seguridad informática"}},
              {"@type":"ListItem", "position":"3", "name":"Seguridad informática en Sevilla", "item": {"@id": "https://www.qualoom.es/seguridad-informatica/sevilla/", "name": "Seguridad informática en Sevilla"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-servicios">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Seguridad informática en SEVILLA</h1>
              <p>Servicios de ciberseguridad, metodologías DevSecOps y auditorías permitirán a tu organización conocer el nivel actual de seguridad y tomar medidas preventivas sobre las carencias identificadas. Piensa como un hacker y actúa como un director de TI concienciando a tu organización y equipo.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="services-main">
        <div className="services-main-content">
          <h2>¿Buscas ayuda de una empresa de seguridad informática?</h2><hr></hr>
          <h3>La importancia de la seguridad informática en las empresas</h3>
          <p>La seguridad en materia de información no es importante en las organizaciones, sino crítica. Organismos, administraciones públicas, usuarios, proveedores y clientes deben estar plenamente informados, concienciados y protegidos de la manera más adecuada contra brechas de seguridad, pérdidas de información y otras alteraciones sobre la principal materia primera de los ecosistemas tecnológicos.</p>
        </div>
      </div>
      <div className="services-main gray">
        <div className="services-main-content">
          <h2>Servicios de Seguridad Informática de Qualoom</h2><hr></hr>
          <h3>¿Qué servicios ofrece Qualoom?</h3><Services></Services><br/>
          <h3>Seguridad informática en remoto</h3><Location></Location>
        </div>
      </div>
      <Footer />
      </div>
  )
}

export default SeguridadSevilla
